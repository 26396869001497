<template>
  <div>
    <el-collapse-transition>
      <!--搜索框-->
      <TableSearch v-show="showSearch" :query-params="queryParams" @search="handleSearch" @reset="handleReset">
        <el-form :model="queryParams" ref="queryForm" label-suffix=":" label-width="100px">

          <el-row :gutter="10">
            <!--<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="10">-->
            <!--  <el-form-item prop="title" label="标题">-->
            <!--    <el-input v-model="queryParams.title" placeholder="请输入标题" clearable/>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->

            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item prop="isShow" label="是否展示">
                <el-select v-model="queryParams.isShow" clearable placeholder="请选择是否展示">
                  <el-option
                      v-for="dict in dict.sys_yes_no"
                      :key="dict.dictValue"
                      :label="dict.dictLabel"
                      :value="dict.dictValue"/>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item label="添加日期">
                <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </TableSearch>
    </el-collapse-transition>

    <div>
      <el-button style="width: 80px" size="mini" type="success" @click="handleSave">添加</el-button>
      <el-button style="width: 80px" size="mini" type="primary" @click="showSearch = !showSearch">{{showSearch ? '隐藏搜索' : '显示搜索'}}</el-button>
    </div>

    <!--列表-->
    <div>
      <el-table
          :data="tableData"
          stripe
          style="width: 100%; font-size: 15px"
          size="medium"
          @sort-change="handleSortChange"
          :default-sort = "{prop: 'sort', order: 'ascending'}"
      >
        <el-table-column type="index" width="50"/>

        <el-table-column label="图片" width="200">
          <template slot-scope="scope">
            <el-image
                style="width: 50px; height: 30px;"
                :src="scope.row.url"
                :preview-src-list="[scope.row.url]"
            >
            </el-image>
          </template>
        </el-table-column>

        <!--<el-table-column prop="title" label="标题" show-overflow-tooltip/>-->

        <el-table-column label="关联文章" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.articleTitle }}</span>
          </template>
        </el-table-column>

        <el-table-column label="是否展示" width="150">
          <template slot-scope="scope">
            <dict-tag :options="dict.sys_yes_no" :value="scope.row.isShow"></dict-tag>
          </template>
        </el-table-column>

        <el-table-column prop="sort" label="排序" width="150" sortable="custom"/>

        <el-table-column prop="createTime" label="添加时间" width="200" sortable="custom"/>

        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="handleUpdate(scope.row)">修改</el-button>
            <el-button type="text" class="color-danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination
          v-show="queryParams.total>0"
          :total="queryParams.total"
          :page.sync="queryParams.curPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
      />
    </div>

    <!--新增、编辑弹框-->
    <CommonDialog ref="dialog" :title="dialogInfo.title" :type="dialogInfo.type" :disabled="dialogInfo.disabled"
                  @confirm="handleSubmitConfirm" @close="handleClose" width="500px" top="100">

      <div style="width: 400px">
        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">

          <el-form-item label="" prop="id">
            <el-input v-show="false" v-model="formData.id"></el-input>
          </el-form-item>

          <el-form-item label="" prop="url">
            <el-input v-show="false" v-model="formData.url"></el-input>
          </el-form-item>

          <el-form-item label="图片" prop="fileId">
            <el-input type="number" v-show="false" v-model="formData.fileId"></el-input>
            <el-upload
                class="avatar-uploader"
                :headers="{Authorization: token}"
                :action="uploadUrl"
                :show-file-list="false"
                :multiple="false"
                :on-success="handleImageSuccess"
                :before-upload="beforeImageUpload">
              <img v-if="formData.url" :src="formData.url" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <!--<el-form-item label="标题" prop="title">-->
          <!--  <el-input v-model="formData.title" placeholder="标题" clearable/>-->
          <!--</el-form-item>-->

          <el-form-item label="是否展示" prop="isShow">
            <el-select v-model="formData.isShow" clearable placeholder="请选择是否展示">
              <el-option
                  v-for="dict in dict.sys_yes_no"
                  :key="Number(dict.dictValue)"
                  :label="dict.dictLabel"
                  :value="Number(dict.dictValue)"/>
            </el-select>
          </el-form-item>

          <el-form-item label="排序" prop="sort">
            <el-input v-model="formData.sort" min="1" max="99999" type="number" placeholder="请输入排序"/>
          </el-form-item>

          <el-form-item label="关联文章" prop="articleId">
            <el-select v-model="formData.articleId" filterable clearable placeholder="请选择文章">
              <el-option
                  v-for="item in articleList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"/>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </CommonDialog>

  </div>
</template>

<script>
import TableSearch from "@/components/TableSearch/TableSearch.vue";
import Pagination from "@/components/Pagination/index.vue";
import CommonDialog from "@/components/CommonDialog.vue";
import DictTag from "@/components/DictTag/index.vue";
import store from "@/store";

export default {
  name: "index",
  dicts: ['sys_yes_no'],
  components: {DictTag, CommonDialog, Pagination, TableSearch},
  data() {
    return {
      queryParams: {
        total: 0,
        curPage: 1,
        pageSize: 10,
        title: '',
        isShow: null,
        sortColumn: '',
        sortOrder: '',
      },
      tableData: [],
      showSearch: false,
      formData: {
        id: null,
        title: '',
        isShow: null,
        fileId: null,
        url: '',
        articleId: null,
        sort: 99,
      },
      articleList: [],
      token: store.state.userinfo.token,
      uploadUrl: process.env.VUE_APP_BASE_URL + '/file/upload',
      innerHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      rules: {
        //fileId: [
        //  {required: true, message: '请选择图片', trigger: 'blur'}
        //],
        //title: [
        //  {required: true, message: '请输入标题', trigger: 'blur'}
        //],
        isShow: [
          {required: true, message: '请选择是否展示', trigger: ['blur', 'change']}
        ],
      },
      dialogInfo: {
        title: '',
        type: '',
        disabled: false,
      },
      dateRange: [],
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    getList() {
      this.$api.swiper.page(this.$addDateRange(this.queryParams, this.dateRange)).then(res => {
        this.tableData = res.list
        this.queryParams.total = res.total
        this.queryParams.pages = res.pages
      })
    },

    handleSortChange(e) {
      this.queryParams = this.$handleSort(this.queryParams, e)
      this.getList()
    },

    resetFormData() {
      this.$resetForm('form')
    },

    handleSave() {
      this.dialogInfo.title = '新 增'
      this.dialogInfo.type = 'success'
      this.getArticleList()
      this.$refs.dialog.show()
    },

    handleClose() {
      this.resetFormData()
    },

    getArticleList(title = '') {
      this.$api.article.getSelectList(title).then(res => {
        this.articleList = res
      })
    },

    handleView() {

    },

    handleUpdate(row) {
      this.$api.swiper.get(row.id).then(res => {
        this.dialogInfo.title = '修 改'
        this.dialogInfo.type = 'warning'
        this.getArticleList()
        this.$refs.dialog.show()
        this.$nextTick(() => {
          this.formData = res
        })
      })
    },

    handleDelete(row) {
      this.$dialog({
        type: 'danger',
        content: `确认删除这条轮播图吗？`
      }).then(() => {
        this.$api.swiper.del(row.id).then(() => {
          this.getList()
          this.$message.success('删除成功')
        })
      })
    },

    handleSubmitConfirm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            this.$api.swiper.update(this.formData).then(res => {
              this.$message.success('修改成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          } else {
            this.$api.swiper.save(this.formData).then(res => {
              this.$message.success('新增成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          }
        } else {
          this.$message.warning('请填写完整')
          this.$refs.dialog.stopLoading()
        }
      })
    },

    handleImageSuccess(res, file) {
      if (Number(res.code) !== 200) {
        this.$message.error('上传失败，请联系管理员')
        return
      }
      this.formData.fileId = res.data.id
      this.formData.url = URL.createObjectURL(file.raw);
    },

    beforeImageUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt6M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt6M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isJPG && isLt6M;
    },

    handleSearch() {
      this.queryParams.curPage = 1
      this.getList()
    },

    handleReset() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.$nextTick(() => {
        this.handleSearch()
      })
    },
  }
}
</script>

<style scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

::v-deep .avatar {
  width: 320px;
  height: 178px;
  display: block;
}

</style>
